<template>
  <div>

    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="发布时间">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="发布国家">
        <el-select v-model="dataForm.sendCountry" clearable placeholder="all" filterable>
          <el-option v-for="item in countryCodeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户语种">
        <el-select v-model="dataForm.sendLang" clearable placeholder="all">
          <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布状态">
        <el-select v-model="dataForm.status" clearable placeholder="all">
          <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发送用户">
        <el-select v-model="dataForm.receiverUserCategory" clearable placeholder="all">
          <el-option v-for="item in receiverUserOpt" :key="item.id" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色IP">
        <el-select v-model="dataForm.ipList" clearable placeholder="all" @change="selectIpChange" filterable>
          <el-option v-for="item in virIPOpt" :key="item.ip" :label="item.ipName" :value="item.ip">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发送角色">
        <el-select v-model="dataForm.virtualId" clearable placeholder="请选择" filterable>
          <el-option v-for="item in virUserOpt" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息类型">
        <el-select v-model="dataForm.msgType" clearable placeholder="请选择">
          <el-option v-for="item in msgTypeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()" type="primary">查询</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="addOrUpdateHandle(0)" type="danger">新增</el-button>

      </el-form-item>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="sendTime" header-align="center" align="center" label="发送时间" width="100">
      </el-table-column>
      <el-table-column prop="id" header-align="center" align="center" label="push-id">
      </el-table-column>
      <el-table-column prop="sendCountry" header-align="center" align="center" label="发送国家">
      </el-table-column>
      <el-table-column prop="sendLang" header-align="center" align="center" label="用户语种">
      </el-table-column>
      <el-table-column prop="status" header-align="center" align="center" label="发布状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 1" size="small">导入用户中</el-tag>
            <el-tag v-if="scope.row.status === 2" type="warning" size="small">待发送</el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="success" effect="plain" size="small">发送中</el-tag>
            <el-tag v-else-if="scope.row.status === 4" type="success" size="small">发送成功</el-tag>
            <el-tag v-else-if="scope.row.status === 0" type="danger" size="small">已终止</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="receiverUserCategory" header-align="center" align="center" label="发送用户">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.receiverUserCategory === 0">ALL</span>
            <span v-else-if="scope.row.receiverUserCategory === 1">当日未报活</span>
            <span v-else-if="scope.row.receiverUserCategory === 2">当日已报活</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="pushContent" header-align="center" align="center" label="push内容">
      </el-table-column>


      <el-table-column prop="ipList" header-align="center" width="200px" align="center" label="角色IP">
        <template slot-scope="scope">
          {{ getLabel(virIPOpt, scope.row.ipList, 'ip', 'ipName') }}
        </template>
      </el-table-column>
      <el-table-column prop="virtualId" header-align="center" align="center" label="发布角色" width="200px">
        <template slot-scope="scope">
          {{ getVirUserLabel(scope.row.ipList, scope.row.virtualId,) }}
        </template>
      </el-table-column>
      <el-table-column prop="pushImage" header-align="center" align="center" label="push配图" width="100">
        <template slot-scope="scope">
          <img v-if="scope.row.pushImage" style="width: 40px; height: 40px" :src="scope.row.pushImage" />
          <span v-else> / </span>
        </template>
      </el-table-column>

      <el-table-column prop="inspirationFromIntroduce" header-align="center" align="center" label="首句灵感">
      </el-table-column>

      <el-table-column prop="inspirationFromPushContent" header-align="center" align="center" label="push灵感">
      </el-table-column>

      <el-table-column prop="msgType" header-align="center" align="center" label="消息类型">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.msgType === 0">文本</span>
            <span v-else-if="scope.row.msgType === 1">语音</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="opName" header-align="center" align="center" label="操作人">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="任务创建时间" width="160">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status !== 0" type="text" style="color: rgba(0, 191, 191, 1)"
            @click="handleStopPush(scope.row.id)">终止任务
          </el-button>
          <el-button type="text" @click="addOrUpdateHandle(scope.row)" style="color: rgba(236, 128, 141, 1)">查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>

  </div>
</template>

<script>
import { langOpt, pickerOptions } from "@/views/common/commonOpt";
import AddOrUpdate from "./talkPush-add-or-update";

const sourceDataForm = {
  id: '',
  sendLang: '',
  status: '',
  ipList: '',
  virtualId: '',
  sendCountry: '',
  receiverUserCategory: '',
  startTime: '',
  endTime: '',
  createTimeArr: [],
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      pickerOptions: pickerOptions,
      virIPOpt: [],
      virUserOpt: [],
      virMapOpt: [],
      statusOpt: [
        {
          value: 0,
          label: '已终止'
        },
        {
          value: 1,
          label: '导入用户中'
        },
        {
          value: 2,
          label: '待发送'
        },
        {
          value: 3,
          label: '发送中'
        },
        {
          value: 4,
          label: '发送成功'
        },
      ],
      receiverUserOpt: [
        {
          value: 0,
          label: 'ALL'
        },
        {
          value: 1,
          label: '当日未报活'
        },
        {
          value: 2,
          label: '当日已报活'
        },
      ],
      countryCodeOpt: [
        {
          value: 'all',
          label: 'ALL'
        }
      ],
      msgTypeOpt: [
      {
          value: 0,
          label: '文本'
        },
        {
          value: 1,
          label: '语音'
        }
      ],
      langOpt: langOpt,
      addOrUpdateVisible: true,
    }
  },
  components: {
    AddOrUpdate
  },
  created() {
    this.getIpList()
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    getIpList() {
      this.$http({
        url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=0`),
        method: 'post',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.result) {
            this.virIPOpt = JSON.parse(JSON.stringify(data.result))
            this.virMapOpt = JSON.parse(JSON.stringify(data.result))
            this.virIPOpt.map(e => { delete e.virUserResDtoList }) //然后删除属性virUserResDtoList
            this.virIPOpt = this.virIPOpt.filter(item => !["", null, undefined, NaN].includes(item.ip))
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    selectIpChange(val) {
      this.virUserOpt = []
      this.dataForm.virtualId = ''
      if (val) {
        this.$http({
          url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=${val}`),
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            if (data.result) {
              this.virUserOpt = data.result[0].virUserResDtoList
              let map = new Map();
              for (let item of this.virUserOpt) {
                if (!map.has(item.id)) {
                  map.set(item.id, item)
                }
              }
              this.virUserOpt = [...map.values()]
            }
          }
        })
      } else {
        this.dataForm.virtualId = ''
      }
    },
    getLabel(list, id, value, label) {
      if (id != '' && Array.isArray(list) && list.length != 0) {
        return !list.find(item => item[value] == id) ? id : list.find(item => item[value] == id)[label]
      } else {
        return id
      }
    },
    getVirUserLabel(ip, virUserId) {
      let virUserOpt = []
      let ipOpt = this.virMapOpt.find(item => item['ip'] == ip)
      virUserOpt = !ipOpt ? [] : ipOpt.virUserResDtoList

      let userOpt = virUserOpt.find(item => item['id'] == virUserId)
      return !userOpt ? virUserId : userOpt['name']
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/officialDialoguePush/list'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0 && data.result) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row)
      })
    },
    handleStopPush(id) {
      this.$confirm('点击后任务下配置push将不会再被发送，请确认是否取消推送此任务', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/officialDialoguePush/pause?taskId=${id}`),
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList()
            this.$message({
              message: '终止任务成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.visible = false
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
</style>
