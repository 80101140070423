<template>
  <div>
    <el-dialog width="60%" :title="!dataForm.id ? '新增' : '查看'" :close-on-click-modal="false" :visible.sync="visible"
      :before-close="handleCloseDialog">
      <h3 style="margin:0 0 0 2vh;padding: 2vh 0; border-top: 1px solid rgba(184, 184, 184, 0.6)">Push内容信息配置</h3>
      <el-form :model="dataForm" ref="dataForm" label-width="160px" :rules="dataRule">

        <el-form-item label="1.Push内容：" prop="pushContent">
          <el-input :readonly="id !== 0" type="textarea" placeholder="请输入内容" v-model="dataForm.pushContent"
            class="input-width" resize="none" :rows="3">
          </el-input>
        </el-form-item>
        <div style="display: flex;">
          <el-form-item label="消息类型" prop="msgType">
            <el-radio-group v-model="dataForm.msgType" clearable :disabled="id !== 0" filterable>
              <el-radio :label="0">文本</el-radio>
              <el-radio :label="1">语音</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="2.Push配图：" prop="pushImage">
            <el-upload class="avatar-uploader" :action="ossUploadUrl" :show-file-list="true"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :file-list="imageList" :limit="1"
              :on-remove="handleAvatarRemove" :disabled="id !== 0">
              <img v-if="dataForm.pushImage" :src="dataForm.pushImage" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="图片是否在对话显示：" prop="imageInDialogue" label-width="200px">
            <el-select :disabled="id !== 0" v-model="dataForm.imageInDialogue" placeholder="请选择">
              <el-option v-for="item in isShowPicOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <h4 style="margin: 0 0 2vh 3vw; font-weight: 400;">3.发布角色：</h4>
          <div style="display: flex;">

            <el-form-item label="角色IP：" prop="ipList">
              <el-select v-model="dataForm.ipList" clearable placeholder="请选择" @change="selectIpChange"
                :disabled="id !== 0" filterable>
                <el-option v-for="item in virIPOpt" :key="item.ip" :label="item.ipName" :value="item.ip">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="发布角色：" prop="virtualId">
              <el-select v-model="dataForm.virtualId" clearable placeholder="请选择" :disabled="id !== 0" filterable>
                <el-option v-for="item in virUserOpt" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <h3 style="margin:0 0 1vh 2vh; padding: 3vh 0; border-top: 1px solid rgba(184, 184, 184, 0.6)">Push推送信息配置</h3>

        <div style="display: flex;">
          <el-form-item label="发布国家：" prop="sendCountry">
            <el-select v-model="dataForm.sendCountry" clearable placeholder="请选择" :disabled="id !== 0">
              <el-option v-for="item in countryCodeOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户语种：" prop="sendLang">
            <el-select v-model="dataForm.sendLang" clearable placeholder="请选择" :disabled="id !== 0">
              <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div style="display: flex; margin-top: 2vh;">
          <el-form-item label=" 发布时间：" prop="sendTime">
            <el-date-picker :disabled="id !== 0" v-model="dataForm.sendTime" placeholder="任意时间点"
              value-format="yyyy-MM-dd HH:mm:ss" type="datetime"></el-date-picker>
          </el-form-item>
          <el-form-item label="发送用户" prop="receiverUserCategory">
            <el-select :disabled="id !== 0" v-model="dataForm.receiverUserCategory" clearable placeholder="请选择">
              <el-option v-for="item in receiverUserOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <template v-if="id === 0">
          <el-button @click="handleCloseDialog">取消</el-button>
          <el-button type="primary" @click="beforeSubmit()">确定</el-button>
        </template>

      </span>
    </el-dialog>

  </div>
</template>

<script>
import { langOpt } from "@/views/common/commonOpt";

const sourceDataForm = {
  // id: 0,
  pushImage: '',
  pushContent: '',
  sendCountry: '',
  sendLang: '',
  sendTime: '',
  imageInDialogue: 0,
  ipList: '',
  virtualId: '',
  msgType: '',
  receiverUserCategory: '',
}
export default {
  data() {
    return {
      id: '',
      visible: false,
      imageList: [],
      virIPOpt: [],
      virUserOpt: [],
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      countryCodeOpt: [
        {
          value: 'all',
          label: 'ALL'
        },
      ],
      isShowPicOpt: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        },
      ],
      receiverUserOpt: [
        {
          value: 0,
          label: 'ALL'
        },
        {
          value: 1,
          label: '当日未报活'
        },
        {
          value: 2,
          label: '当日已报活'
        },
      ],
      msgTypeOpt: [
      {
          value: 0,
          label: '文本'
        },
        {
          value: 1,
          label: '语音'
        }
      ],
      langOpt: langOpt,
      dataRule: {
        'ipList': [
          { required: true, message: '角色IP不能为空', trigger: 'blur' }
        ],
        'virtualId': [
          { required: true, message: '发布角色不能为空', trigger: 'blur' }
        ],
        'sendCountry': [
          { required: true, message: '发布国家不能为空', trigger: 'blur' }
        ],
        'sendLang': [
          { required: true, message: '发送语种不能为空', trigger: 'blur' }
        ],
        'sendTime': [
          { required: true, message: '发送时间不能为空', trigger: 'blur' }
        ],
        'pushContent': [
          { required: true, message: '发送内容不能为空', trigger: 'blur' }
        ],
        'receiverUserCategory': [
          { required: true, message: '发送用户不能为空', trigger: 'blur' }
        ],
        'msgType': [
          { required: true, message: '消息类型不能为空', trigger: 'blur' }
        ],
      },
    }
  },
  created() {
    this.ossUploadUrl = this.$http.adornUrl(
      `/sys/oss/upload/compress?token=${this.$cookie.get("token")}`
    );
    this.getIpList();
  },
  methods: {
    init(row) {
      // console.log('row', row)
      this.imageList = []

      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.id = !row ? 0 : row.id

      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return;
      }

      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

      this.visible = true
      const { pushImage, sendCountry, sendLang, sendTime, pushContent, imageInDialogue, ipList, virtualId, msgType, receiverUserCategory } = row
      this.dataForm.pushImage = pushImage
      this.dataForm.sendLang = sendLang
      this.dataForm.sendCountry = sendCountry
      this.dataForm.sendTime = sendTime
      this.dataForm.pushContent = pushContent
      this.dataForm.imageInDialogue = imageInDialogue
      this.dataForm.ipList = ipList
      this.dataForm.virtualId = virtualId
      this.dataForm.msgType = msgType
      this.dataForm.receiverUserCategory = receiverUserCategory

      if (this.id !== 0) {
        this.selectIpChange(this.dataForm.ipList)
      }
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 jpeg/png/gif 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },

    handlePreview(file) {
      // 上传文件路径存在时可预览
      if (this.dataForm.packageUrl) {
        window.open(
          this.dataForm.packageUrl,
          "_blank"
        );
      } else {
        this.$message.warning("暂不支持预览");
      }
    },

    handleAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.pushImage = res.url
      } else {
        this.$message.error('上传图片失败:' + res.msg)
      }
    },

    handleAvatarRemove(res, file) {
      this.dataForm.pushImage = ''
    },

    beforeSubmit() {
      this.$confirm('当前配置信息是否已确认无误?', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },

    handleCloseDialog() {
      this.visible = false
      this.imageList = []
    },

    getIpList() {
      this.$http({
        url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=0`),
        method: 'post',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.result) {
            this.virIPOpt = JSON.parse(JSON.stringify(data.result))
            this.virIPOpt = this.virIPOpt.filter(item => !["", null, undefined, NaN].includes(item.ip))
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    selectIpChange(val) {
      if (this.id === 0) {
        this.dataForm.virtualId = ''
      }
      if (val) {
        this.$http({
          url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=${val}`),
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            if (data.result.length !== 0) {
              this.virUserOpt = data.result[0].virUserResDtoList
              let map = new Map();
              for (let item of this.virUserOpt) {
                if (!map.has(item.id)) {
                  map.set(item.id, item)
                }
              }
              this.virUserOpt = [...map.values()]
            }
            console.log('this.virUserOpt', this.virUserOpt)
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log('dataForm', this.dataForm)

          this.$http({
            url: this.$http.adornUrl(`/admin/officialDialoguePush/add`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-width {
  width: 35vw;
  margin-bottom: 2vh;
}
</style>